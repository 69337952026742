import {Button, Input, Modal, ModalBody} from "reactstrap";
import {colors, largeButtonStyle} from "../../styles/BearStyles";
import React, {useState} from "react";


const QuickScanPickFulfillmentLine = (props) => {
    const items = props.items;
    const scanPick = props.scanPick;
    const [search, setSearch] = useState('');
    const [lineIndex, setLineIndex] = useState(-1);
    const [error, setError] = useState('');
    const [info, setInfo] = useState('');
    const [modal, setModal] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    
    const handleChange = (e) => setSearch(e.target.value);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (lineIndex < 0){
            const index = items.findIndex(i => i.upccode === search);
            if (index < 0){
                setError('ITEM NOT FOUND');
            } else {
                if (items[index].picked){
                    setError('ALREADY PICKED ' + items[index].itemid);
                    return;
                }
                setError('');
                setInfo(items[index].itemid + ": SCAN BIN")
            }
            setLineIndex(index);
            setSearch('');
            return;
        }
        
        const match = items[lineIndex].inventorydetail[0].binnumber.toUpperCase() === search.toUpperCase();
        if (match){
            //PICKED CORRECTLY
            openModal();
        }
        else{
            setError('INCORRECT BIN');
        }
    }
    
    const openModal = () => {
        setModalItem(items[lineIndex]);
        setModal(true);
    }
    
    const completePick = () => {
        scanPick(lineIndex);
        clearItem();
    }
    
    const clearItem = () => {
        setLineIndex(-1);
        setSearch('');
        setError('');
        setInfo('');
        setModalItem(null);
        setModal(false);
    }
    

    return (
        <>
            <form onSubmit={handleSubmit} style={{marginBottom: 10, marginTop: 10}}>
                <Input autoFocus type={"text"} placeholder={'Scan'} value={search} onChange={handleChange}/>
                <input hidden type={"submit"} value={"Search"} style={largeButtonStyle}/>
            </form>
            <h3 style={{color: 'green', fontWeight: 'bold', fontStyle: 'normal'}}>{info}</h3>
            <h3 style={{color: 'red', fontWeight: 'bold', fontStyle: 'normal'}}>{error}</h3>
            {lineIndex >= 0 && <Button onClick={clearItem}>CLEAR</Button>}
            <Modal isOpen={modal} fullscreen>
                <ModalBody>
                    {modalItem &&
                        <div style={{textAlign: 'center'}}>
                            <h1 style={{marginTop: 40, marginBottom: 20, fontSize: 55}}>{modalItem.itemid}</h1>
                            <h3 style={{marginTop: 20, marginBottom: 20, fontSize: 30}}>{modalItem.displayname}</h3>
                            <h1 style={{marginTop: 20, marginBottom: 5, fontSize: 90, backgroundColor: colors.highlight}}>
                                {modalItem.quantity}
                                <span style={{fontSize: 22}}> UNITS</span>
                            </h1>
                            <h1 style={{marginBottom: 30, fontSize: 20}}>Bin:</h1>
                            <h2 style={{marginBottom: 50, fontSize: 60}}>{modalItem.inventorydetail[0].binnumber}</h2>
                            <Button onClick={completePick}
                                    style={{...largeButtonStyle, backgroundColor: colors.blue}}>CONFIRM</Button>
                            <Button onClick={clearItem}
                                    style={{...largeButtonStyle, backgroundColor: colors.error}}>CANCEL</Button>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </>

    )
}

export default QuickScanPickFulfillmentLine;