import React, {useState} from "react";
import {Button} from "reactstrap";
import {colors, largeButtonStyle} from "../../styles/BearStyles";
import secureStorage from "react-secure-storage";
import Loading from "../utility/Loading";
import VerifyItem from "./VerifyItem";

const PickFulfillmentLine = ({fulfillment, item, inventorydetail, cancel}) => {
    const [alternateBins, setAlternateBins] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    //const [itemVerified, setItemVerified] = useState(false);
    const [binid, setBinid] = useState('default');
    
     const loadAlternateBins = async () => {
         setLoading(true);
         const user = secureStorage.getItem('user');
         const requestOptions = {
             method: 'GET',
             headers: { 'Authorization': `Bearer ${user.token}` },
         };
         const location = user.location.toString();
         const iteminternalid = item.iteminternalid.toString();
         const bin = inventorydetail[0].bininternalid.toString();
         const quantity = item.quantity.toString();
         const response = await fetch(`api/Fulfillments/alternatebins/${location}/${iteminternalid}/${bin}/${quantity}`, requestOptions);
         const data = await response.json();
         setAlternateBins(data);
         setLoading(false);
     }
     
     const startVerifyItem = (alternateBinId) => {
         setIsVerifying(true);
         setBinid(alternateBinId);
     }
     
     const pickDefaultBin =  () => {
         setLoading(true);
          let items = secureStorage.getItem(fulfillment.internalid);
          items.forEach(i => {
              if (i.lineid === item.lineid)
                  i.picked = true;
          })
         secureStorage.setItem(fulfillment.internalid, items);
         setLoading(false);
          cancel();
     }

    const undoPick =  () => {
        setLoading(true);
        let items = secureStorage.getItem(fulfillment.internalid);
        items.forEach(i => {
            if (i.lineid === item.lineid) {
                i.picked = false;
            }
        })
        secureStorage.setItem(fulfillment.internalid, items);
        setLoading(false);
        cancel();
    }
     
     const pickAlternateBin = async (bininternalid) => {
         setLoading(true);
         const user = secureStorage.getItem('user');
         const requestOptions = {
             method: 'GET',
             headers: { 'Authorization': `Bearer ${user.token}` },
         };
         const fulfillmentid = fulfillment.internalid.toString();
         const alternatebinid = bininternalid.toString();
         const lineid = item.lineid.toString();
         const username = user.username;
         const quantity = item.quantity.toString();
         await fetch(`api/Fulfillments/pickAlternateBin/${inventorydetail[0].internalid}/${fulfillmentid}/${lineid}/${alternatebinid}/${quantity}/${username}`, requestOptions);
         setLoading(false);
         window.location.reload();
     }
     
     if (loading){
         return <Loading />
     }
    
    return (
        <>
            <h3><Button onClick={cancel}>Cancel</Button>  {item.itemid}</h3>
            <h5>{item.displayname}</h5>
            <h3>QUANTITY: {item.quantity}</h3>
            {
                isVerifying ? <VerifyItem item={item} binid={binid} pickDefaultBin={pickDefaultBin} pickAlternateBin={pickAlternateBin} /> :

                    item.bmsstatus === 'picked' || item.picked ?
                        <Button onClick={undoPick} style={{...largeButtonStyle, backgroundColor: colors.error}}>UNDO PICK</Button>
                        :
                        <>
                            <Button onClick={() => startVerifyItem('default')} style={{...largeButtonStyle, backgroundColor: colors.green}}>
                                {inventorydetail ?
                                    inventorydetail.map((line) => <div key={line.binnumber}>PICK {line.binnumber} (QTY: {line.quantity})</div>)
                                    :
                                    'No Bin'
                                }
                            </Button>
                            
                            {alternateBins.length > 0 &&
                                alternateBins.map(alternateBin =>
                                    <Button key={alternateBin.bininternalid} style={{...largeButtonStyle, backgroundColor: colors.blue}} onClick={() => startVerifyItem(alternateBin.bininternalid)}>
                                        {<div key={alternateBin.bininternalid}>PICK {alternateBin.binnumber} (QTY: {item.quantity}) {alternateBin.memo && `MEMO: ${alternateBin.memo}`}</div>}
                                    </Button>
                                )
                            }
                        </>
            }
        </>
    )
}

/*
{alternateBins.length === 0 && <Button style={largeButtonStyle} onClick={loadAlternateBins}>Load Alternate Bins</Button>}
 */

export default PickFulfillmentLine;