import BackButton from "../components/utility/BackButton";
import React, {useState} from "react";
import secureStorage from "react-secure-storage";
import {useNavigate} from "react-router-dom";
import Loading from "../components/utility/Loading";
import {Input} from "reactstrap";
import {largeButtonStyle} from "../styles/BearStyles";
import AccessDenied from "../components/utility/AccessDenied";


const MapleQcPage = () => {
    const user = secureStorage.getItem("user");
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const user = secureStorage.getItem("user");
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${user.token}` },
        };
        if (search.length < 2){
            setError('Search must be more than 2 characters');
            return;
        }
        setLoading(true);
        fetch(`api/Fulfillments/search/${search}`, requestOptions)
            .then(response => {
                if (response.ok){
                    response.text().then(result => {
                        if (result === '' || result === null){
                            setLoading(false);
                            setError('Not Found');
                        } else {
                            navigate('/qcFulfillments/' + result);
                        }
                    })
                } else {
                    console.log("Failed");
                }
            })
    }

    if (loading) return <Loading />

    return (
        (user && user.qcmapleorders) ?
            <>
                <form onSubmit={handleSubmit}>
                    <h1><BackButton to={'/'} />   QC Maple</h1>
                    <Input autoFocus type={"text"} value={search} onChange={(e)=>setSearch(e.target.value)}/>
                    <input type={"submit"}
                           value={"Submit"}
                           style={largeButtonStyle}
                    />
                    <p style={{textAlign: 'center', marginTop: 5}}>{error}</p>
                </form>
            </>
            :
            <AccessDenied />
    );
}

export default MapleQcPage;