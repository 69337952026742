import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Input} from "reactstrap";
import {largeButtonStyle} from "../styles/BearStyles";
import Loading from "../components/utility/Loading";
import secureStorage from "react-secure-storage";
import AccessDenied from "../components/utility/AccessDenied";

const PickOrdersPage = () => {
    const user = secureStorage.getItem("user");
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const user = secureStorage.getItem("user");
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${user.token}` },
        };
        if (search.length < 2){
            setError('Search must be more than 2 characters');
            return;
        }
        setLoading(true);
        const response = await fetch('api/Fulfillments/search/' + search, requestOptions);
        if (response.status !== 200){
            setLoading(false);
            setError('Not found');
            return;
        }
        const result = await response.text();
        navigate('/fulfillments/' + result);
    }
    
    if (loading) return <Loading />

    return (
        (user && user.vieworders) ?
        <>
            <form onSubmit={handleSubmit}>
                <h1><Button onClick={() => navigate('/')}>Back</Button>   Search Fulfillments</h1>
                <Input autoFocus type={"text"} value={search} onChange={(e)=>setSearch(e.target.value)}/>
                <input type={"submit"}
                       value={"Submit"}
                       style={largeButtonStyle}
                />
                <p style={{textAlign: 'center', marginTop: 5}}>{error}</p>
            </form>
            <Button style={largeButtonStyle} onClick={() => navigate('/fulfillments')}>View All</Button>
        </>
        :
            <AccessDenied />
    );
}

export default PickOrdersPage;