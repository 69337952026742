import {Button, Input} from "reactstrap";
import {colors, largeButtonStyle} from "../../styles/BearStyles";
import {useState} from "react";
import secureStorage from "react-secure-storage";


const VerifyItem = ({item, binid, pickDefaultBin, pickAlternateBin}) => {
    const [search, setSearch] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();
        const user = secureStorage.getItem('user');
        if (search.length < 2){
            setErrorMessage('No Match')
            return;
        }
        const match = search === item.upccode;
        if (!match){setErrorMessage("Item does not match.");return;}
        
        if (binid === 'default') {
            pickDefaultBin();
            return;
        }
        pickAlternateBin(binid);
    }
    
    const skipVerify = () => {
        if (binid === 'default') {
            pickDefaultBin();
            return;
        }

        pickAlternateBin(binid);
    }
    
    return (
        <>
            <hr />
            <form onSubmit={handleSubmit}>
                <h1 style={{textAlign: 'center'}}>Scan Item Barcode</h1>
                <Input autoFocus type={"text"} value={search} onChange={(e) => setSearch(e.target.value)}/>
                <input type={"submit"}
                       value={"Submit"}
                       style={{...largeButtonStyle, backgroundColor: colors.green}}
                />
                <h4 style={{textAlign: 'center', marginTop: 5, fontWeight: 'bold', color: colors.error}}>{errorMessage}</h4>
            </form>
            <Button style={largeButtonStyle} onClick={skipVerify}>Skip Scanning</Button>
        </>
    );
}

export default VerifyItem;