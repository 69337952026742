import secureStorage from "react-secure-storage";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Loading from "../components/utility/Loading";
import BackButton from "../components/utility/BackButton";
import {Button} from "reactstrap";
import {largeButtonStyle} from "../styles/BearStyles";


const FulfillmentQcPage = () => {
    const user = secureStorage.getItem("user");
    let {id} = useParams();
    const [fulfillment, setFulfillment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState('');
    
    useEffect(() => {
        fetchFulfillment();
    },[])
    
    const fetchFulfillment = () => {
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${user.token}` },
        };
        fetch('api/fulfillments/' + user.location + '/' + id, requestOptions)
            .then((response) => response.json()
                .then((result) =>
                    {
                        console.log(result);
                        setFulfillment(result[0]);
                        setLoading(false);
                    }));
    }
    
    const handleMarkQC = () => {
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${user.token}` },
        };
        fetch(`api/Fulfillments/markFulfillmentQC/${fulfillment.internalid}/${user.username}`, requestOptions)
            .then(response => {
                if (response.ok){
                    setLoading(false);
                    setSuccess('QC Complete')
                } else {
                    setLoading(false);
                    setSuccess('FAILED');
                }
            }).catch(e => {
                setLoading(false);
                setSuccess(e.toString);
        })
    }
    
    if (loading) return <Loading />
    
    return (
        <>
            <h1><BackButton to={-1}/> {fulfillment.tranid} ({fulfillment.sonumber})</h1>
            <h3 style={{fontWeight: 'bold'}}>{fulfillment.name}</h3>
            <h4>Ship Date: {fulfillment.shipdate}</h4>
            {
                fulfillment && !success && 
                <Button name={"markQC"} onClick={handleMarkQC} style={largeButtonStyle}>Mark QC</Button>
            }
            <h2>{success}</h2>
        </>
)
    ;
}

export default FulfillmentQcPage;